document.addEventListener('alpine:init', () => {
  window.Alpine.store('global', {
    primaryMenuOpen: false,

    changeAspect() {
      if (Alpine.store('global').primaryMenuOpen) {
        document.querySelector('body').style.overflowY = 'hidden'
        document.querySelector('[data-header]').style.right = 'var(--scrollbar-width)'
        document.querySelector('body').style.paddingRight = 'var(--scrollbar-width)'
        
      } else {
        document.querySelector('body').style.overflowY = 'visible'
        document.querySelector('[data-header]').style.right = '0'
        document.querySelector('body').style.paddingRight = '0'
      }
    },
  })
})