import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
Alpine.plugin(collapse)
Alpine.plugin(focus)
window.Alpine = Alpine
import { video_block } from '@scripts/video-block.js';
import { getHeight, getWidth } from '@scripts/utils.js'
import { setScrollbarWidth } from '@scripts/utils.js'
video_block();
window.Alpine.start()

window.addEventListener(
  "load",
  () => {
    transition();
    getHeight(['[data-header]', '#wpadminbar', '[data-approach-nav]']);
    getWidth(['[data-cta-approach-item]']);
    setScrollbarWidth();
    setNavigation();
    responsiveTable();
  },
);

window.addEventListener(
  "resize",
  () => {
    getHeight(['[data-header]', '#wpadminbar', '[data-approach-nav]']);
    getWidth(['[data-cta-approach-item]']);
    setScrollbarWidth();
    setNavigation();
  },
);

window.addEventListener("mapRefresh", () => setLoading());

function setLoading() {
  var isContentLoading = false;
  isContentLoading = ! isContentLoading

  if (!!document.querySelector('[data-loading]')) {
    document.querySelectorAll('[data-loading]').forEach((item) => {

      if (isContentLoading) {
        item.classList.remove("active")
      }
    });
  }
}

import '@/stores'

function transition() {
  setTimeout(() => {
    const transition = document.querySelector("html");
    transition.classList.add("disable");
  }, 400)
}

function scrollToTop() {
  if (!!document.querySelector('[scrollToTop]')) {
    const scrollBtn = document.querySelector('[scrollToTop]');

  const btnVisibility = () => {
    if (window.scrollY > 400) {
      scrollBtn.style.opacity = 1;
    } else {
      scrollBtn.style.opacity = 0;
    }
  };

  document.addEventListener("scroll", () => {
    btnVisibility();
  });

  scrollBtn.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
  }
}

scrollToTop();

function setupAnchorsSmoothScroll() {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      let headerHeight = document.querySelector('[data-header]').offsetHeight;

      const targetId = this.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - headerHeight,
          behavior: 'smooth'
        });
      }
    });
  });
}

setupAnchorsSmoothScroll();

function setNavigation() {
  if (!!document.querySelector('[data-nav-open]') && !!document.querySelector('[data-nav-close]')) {
    document.querySelectorAll('[data-nav-open]').forEach((item) => {
      item.addEventListener('click', function () {
        this.closest('[data-nav-parent]').classList.add("active")
      });
    });
  
    document.querySelectorAll('[data-nav-close]').forEach((item) => {
      item.addEventListener('click', function () {
        this.closest('[data-nav-parent]').classList.remove("active")
      });
    });
  }
}

function responsiveTable() {
  if (!!document.querySelector('.text table')) {
    [...document.querySelectorAll('.text table')].forEach((el) => {
      let content = el.outerHTML
      el.outerHTML = "<div class='overflow-x-auto block w-full'>" + content + "</div>"
    })
  }
}