export function video_block() {
  document.addEventListener('alpine:init', () => {
    
    window.Alpine.data('remoteVideo', ( videoSrc ) => ({
      launched: false,
      states: {
        hover: false,
      },
      videoSrc,

      handleMouseEnter() {
        this.states.hover = true
      },

      handleMouseLeave() {
        this.states.hover = false
      },

      play() {
        let video_id = videoSrc.match(/(?:https?:\/{2})?(?:w{3}\.)?vimeo.com([^\s&]+)/)[1];
        let autoplay = (video_id.includes('?') ? '&' : '?') + 'autoplay=1'
        this.$refs.iframeWrapper.innerHTML = `<iframe src="https://player.vimeo.com/video${
          video_id + autoplay
        }" autoplay; fullscreen; picture-in-picture"></iframe>`
        this.launched = true
      },
    }))
  })
}