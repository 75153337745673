document.addEventListener(
  "DOMContentLoaded",
  () => {
    /* Auto Imports */
    const assetContext = require.context(".", true, /\.(asset)\.(.*?)$/);
    assetContext.keys().forEach(assetContext);
    const runtimeContext = require.context(".", true, /\.(runtime)\.(.*?)$/);
    runtimeContext.keys().forEach(runtimeContext);
  },
  { once: true },
);
